<template>
  <div class="upload">
    <kendo-upload ref="upload"
                  name="files"
                  :multiple="multiple"
                  :show-file-list="false"
                  :async-auto-upload="false"
                  :localization-select="'파일 선택'"
                  @select="onSelectFile"
                  :validation-allowed-extensions='[".gif", ".jpg", ".png", ".pdf", ".xlsx" ,".xls",".docx",".doc",".hwp",".txt"]'>
    </kendo-upload>
    <div class="k-upload" v-show="file !== ''">
      <ul class="k-upload-files k-reset" style="width: 100%">
        <li v-bind:id="imageName+index" class="k-file" v-for="(fileItem,index) in file">
          <span class="k-progress" style="width: 100%;"></span>
          <span :id="'previewImage_'+fileItem.uid"></span>
          <span v-if="fileItem.url !== undefined"><img :src="fileItem.url" style='width:70px;height: 70px;'></span>
          <span class="k-file-name-size-wrapper">
            <span class="k-file-name">{{fileItem.name}}</span>
            <span class="k-file-size" v-if="fileItem.size !== undefined">{{Math.round(0.001*parseInt(fileItem.size))}} KB</span>
          </span>
          <strong class="k-upload-status">
            <button type="button" class="k-button k-upload-action" aria-label="Remove" @click="removeExistFiles(imageName,index)">
              <span class="k-icon k-i-close k-i-x" title="Remove"></span>
            </button>
          </strong>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name   : 'FileUpload',
    props  : {
      file     : {
        default:
          []
      },
      multiple : {
        default: true
      },
      imageName: {
        default: null,
      }
    },
    mounted () {

    },
    data () {
      return {
        vvsrc     : '',
        uploadFile: [],
      }
    },
    methods: {
      onSelectFile    : function (e) {
        const vm = this
        const files = e.files;

        if (this.multiple === false) {
          if (document.getElementById(this.imageName + 0) !== null) {
            kendo.alert("파일 한개만 업로드 할 수 있습니다.")
            e.preventDefault()
            return false
          }
        }

        // 확장자 체크
        let fileSpliceIndex = -1
        let fileIndex = this.file.length
        files.forEach(file => {
          //첨부파일이 한페이지에 여러개를 삽입 할때는 imageName을 받아서 처리한다
          if(vm.imageName !== null){
            file.imageName = vm.imageName
            file.seq = fileIndex
          }
          // 불허 확장자 array
          const notAllowedExtArray = [".pdf",".gif",".xlsx" ,".xls",".docx",".doc",".hwp",".txt"]
          if (notAllowedExtArray.includes(file.extension)) {
            // 불허 확장자 파일 index
            fileSpliceIndex = files.indexOf(file)
            kendo.alert(file.extension + "파일은 선택하실 수 없습니다.")
            e.preventDefault()
            return false
          }else if(file.size=== 0){
            fileSpliceIndex = 1
            kendo.alert("파일의 size가 0입니다 파일을 확인해 주세요.")
            e.preventDefault()
            return false
          }else{
            fileIndex++
          }

        })

        if (fileSpliceIndex < 0) {

          const fileInfo = e.files[0];
          this.uploadFile = fileInfo

          setTimeout(function () {
            const raw = fileInfo.rawFile;
            const reader = new FileReader();
            const name = fileInfo.name
            const w = this

            if (raw) {

              reader.onloadend = function () {

                w.document.getElementById("previewImage_" + fileInfo.uid).innerHTML = "<img alt=" + name + " src=" + this.result + " style='width:70px;height: 70px;'>"

              };

              reader.readAsDataURL(raw);
            }
          })

          this.$emit('child', files)
        }

      },
      /*onSelectFile: function (e) {
       const files = e.files;
       this.$emit('child', files)
       },*/
      removeExistFiles: function (imageName, idx) {

        document.getElementById(imageName + idx).remove()

        const id = this.file.findIndex(function (item) {
          return parseInt(item.seq) === parseInt(idx)
        })
        if (id > -1) this.file.splice(id, 1)

      }
    }
  }
</script>
<style>
  .upload span.k-file-extension {
    font-size: 10px !important;
  }
</style>
